import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { IntLinks, ExtLinks } from './Links';

const Layout = ({  children }) => {
  // const { path, uri } = children.props
  const { location } = children.props;
  const { name: pageName } = children.type;
  const NavLinks = pageName && pageName === 'IndexPage' ? IntLinks : ExtLinks;

  return (
    <>
      <Sidebar location={location} {...NavLinks} />
      <Navbar location={location} {...NavLinks} />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
};
export default Layout;
