import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`
export const Nav = styled.nav`
  background: ${({isScrolled}) => (isScrolled ? 'var(--anzr-blue)' : 'transparent')};
  height: 80px;
  margin-top: -80px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top:0;
  z-index:10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`

export const MobileIcon = styled.div`
  display: none;
  
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--anzrblue);
  }
`
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavLogoImg = styled.img`
  width: 100%;
  height:20px;
  margin: 0 0 10px 0;
  padding-right: 0;
  filter: ${({isScrolled}) => (isScrolled ? 'invert(67%) sepia(62%) saturate(437%) hue-rotate(108deg) brightness(84%) contrast(70%);' : 'none')};
`

export const NavMenu = styled.ul`
  display:flex;
  width:100%;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  & > li {
    height: 80px;

  }
    & > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0 1rem;
        height: 100%;
        cursor: pointer;

      }
`
export const NavLogo = styled(Link)`
  color: ${({isScrolled}) => (isScrolled ? 'white' : 'var(--anzr-green)')};
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 80px;
  cursor: pointer;

  justify-self: flex-start;
  font-size: 1.5rem;
  font-weight: bold;
  width: 80px;
  margin-bottom: 2rem;

  &:hover {
    color: var(--anzr-blue);
  }
`
export const Scroll = styled(ScrollLink)`
  color: ${({isScrolled}) => (isScrolled ? 'var(--anzr-green)' : 'var(--anzr-blue)')};
  &.active { border-bottom: 3px solid var(--anzr-green); }
  &:hover { color: ${({isScrolled}) => (isScrolled ? 'white' : 'var(--anzr-green)')}; }
`
export const RouteLink = styled(Link)`
  color: ${({isScrolled}) => (isScrolled ? 'var(--anzr-green)' : 'var(--anzr-blue)')};
  &.active { border-bottom: 3px solid var(--anzr-green); }
  &:hover { color: ${({isScrolled}) => (isScrolled ? 'white' : 'var(--anzr-green)')}; }
`
export const NavBtnLink = styled(ScrollLink)`
  border-radius: 50px;
  background: var(--anzr-green);
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`