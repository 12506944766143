import React from 'react';
import {  FooterContainer, FooterWrap, SocialLogo, SocialMedia,
          SocialMediaWrap, WebsiteRights,
} from '../styles/StyledFooter';

const Footer = () => {
    // const toggleHome = () => {
    //   scroll.scrollToTop();
    // }

    return (
        <FooterContainer>
          <FooterWrap>
            <SocialMedia>
              <SocialMediaWrap>
                {/* <SocialLogo to='/' onClick={toggleHome}> */}
                <SocialLogo to="/">Anzr</SocialLogo>
                <WebsiteRights>Anzr © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
              </SocialMediaWrap>
            </SocialMedia>
          </FooterWrap>
        </FooterContainer>
    );
};

export default Footer
