import React, { createContext } from 'react';

// Prepare data and functions to be used in the reducer
const initialState = {
  isOpen: false,
};

const actions = { SET_MENU: 'SET_MENU' };

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_MENU:
      return { ...state, isOpen: action.value };
    default:
      return state;
  }
};

//  Create context from the scope of the function "ContextProvider"
//  so it can be exported and used in pages

const MenuContext = createContext();

const MenuContextProvider = ({ children }) => {
  // Whitin this component, we create a React-Reducer with the prepared data
  // which gives the current state and a dispatch function to invoke an
  // action of the earlier declared reducer function.

  const [state, dispatch] = React.useReducer(reducer, initialState);

  // With 'state' and 'dispatch', we now can create a object
  // which goes into ContextProvider.

  const value = {
    isOpen: state.isOpen,
    toggleMenu: (value) => {
      dispatch({ type: actions.SET_MENU, value });
    },
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

// The exported ContextProvider will only be used in /src/layout/index.js,
// while the general Context can be used by any Page or Component (with help
// of useContext)
export { MenuContextProvider as default, MenuContext };
