export const ExtLinks = {
    link1: '/#about',
    link2: '/#careers',
    link3: '/#services',
    link4: '/#contact',
    NavHashLink: true
  };
  
  export const IntLinks = {
    link1: 'about',
    link2: 'careers',
    link3: 'services',
    link4: 'contact',
    NavHashLink: false
  };