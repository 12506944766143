/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {  RouteLink, Scroll, Nav, NavbarContainer, NavLogo,
          NavLogoImg, NavMenu, NavBtn, MobileIcon,
} from '../styles/StyledNavbar';
import Icon3 from '../../static/images/Anzr_logo.svg';
import { MenuContext } from '../context';

const scrollToTop = () => {
  scroll.scrollToTop();
};

const Navbar = ({ link1, link2, link3, link4, NavHashLink }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  // use the MenuContext to get/set boolean state of isOpen
  const { isOpen, toggleMenu } = React.useContext(MenuContext);
  // toggle the boolean when onClick={...}
  const toggleOpen = () => { toggleMenu(!isOpen); };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  const NavLink = NavHashLink ? RouteLink : Scroll;
  return (
    <IconContext.Provider value={{ color: 'var(--anzr-green)' }}>
        <Nav isScrolled={isScrolled}>
          <NavbarContainer>
            <NavLogo isScrolled={isScrolled} to='/' src={Icon3} onClick={scrollToTop}>
              <NavLogoImg isScrolled={isScrolled} src={Icon3} />
            </NavLogo>

            <MobileIcon onClick={toggleOpen}> <FaBars /> </MobileIcon>
            <NavMenu>
              <NavLink to={link1} isScrolled={isScrolled} activeClassName='active' smooth="true" duration={500} spy={true} offset={-80} exact='true' >
                About
              </NavLink>
              <NavLink to={link2} isScrolled={isScrolled} activeClassName='active' smooth={true} duration={500} spy={true} offset={-80} exact='true' >
                Careers
              </NavLink>
              <NavLink to={link3} isScrolled={isScrolled} activeClassName='active' smooth={true} duration={500} spy={true} offset={-80} exact='true' >
                Services
              </NavLink>
              <NavLink to={link4} isScrolled={isScrolled} activeClassName='active' smooth={true} duration={500} spy={true} offset={-80} exact='true' >
                Contact
              </NavLink>
              <RouteLink isScrolled={isScrolled} activeClassName='active' onClick={scrollToTop} to='/news'> News </RouteLink>
            </NavMenu>

            <NavBtn>{/* <NavBtnLink to='/signin'>Sign In</NavBtnLink> */}</NavBtn>
          </NavbarContainer>
        </Nav>
    </IconContext.Provider>
  );
};
export default Navbar;
