/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { 
  SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, Scroll, RouteLink 
} from '../styles/StyledSidebar'
import { MenuContext } from '../context'

const Sidebar = ( {link1, link2, link3, link4, NavHashLink} ) => {
  const { isOpen, toggleMenu } = useContext(MenuContext)

  const toggleOpen = () => {
    toggleMenu(!isOpen)
  }

  // Check if links shuld be external/internal for navigation on Index page resp. sub pages
  const NavLink = NavHashLink ? RouteLink : Scroll

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggleOpen}>
      <Icon onClick={toggleOpen}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <NavLink to={link1} onClick={toggleOpen} >About</NavLink>
          <NavLink to={link2} onClick={toggleOpen} >Careers</NavLink>
          <NavLink to={link3} onClick={toggleOpen} >Services</NavLink>
          <NavLink to={link4} onClick={toggleOpen} >Contact</NavLink>
          <RouteLink to='/news' onClick={toggleOpen} >News</RouteLink>
        </SidebarMenu>
        {/* <SideBtnWrap>
          <SidebarRoute to='/signin'>Sign in</SidebarRoute>
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  )
}
export default Sidebar
